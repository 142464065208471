import {
    GET_DEALS,
    GET_DEALS_SUCCESS,
    GET_DEALS_FAILED,

    SUBMIT_DEAL_FORM,
    SUBMIT_DEAL_FORM_SUCCESS,
    SUBMIT_DEAL_FORM_FAILED,

    DELETE_DEAL,
    DELETE_DEAL_SUCCESS,
    DELETE_DEAL_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllDeals = ({offset, limit}) => ({
    type: GET_DEALS,
    payload: { offset, limit }
});

export const getAllDealsSuccess = ({total, data}) => ({
    type: GET_DEALS_SUCCESS,
    payload: {total, data}
});

export const getAllDealsFailed = (error) => ({
    type: GET_DEALS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitDealForm = (deal) => ({
    type: SUBMIT_DEAL_FORM,
    payload: deal
});

export const submitDealFormSuccess = (deal) => ({
    type: SUBMIT_DEAL_FORM_SUCCESS,
    payload: deal
});

export const submitDealFormFailed = (error) => ({
    type: SUBMIT_DEAL_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteDeal = (id) => ({
    type: DELETE_DEAL,
    payload: id
});

export const deleteDealSuccess = (id) => ({
    type: DELETE_DEAL_SUCCESS,
    payload: id
});

export const deleteDealFailed = (error) => ({
    type: DELETE_DEAL_FAILED,
    payload: error
});