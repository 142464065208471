/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

/* COUNTRY  */
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED';

export const SUBMIT_COUNTRY_FORM = 'SUBMIT_COUNTRY_FORM';
export const SUBMIT_COUNTRY_FORM_SUCCESS = 'SUBMIT_COUNTRY_FORM_SUCCESS';
export const SUBMIT_COUNTRY_FORM_FAILED = 'SUBMIT_COUNTRY_FORM_FAILED';

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAILED = 'DELETE_COUNTRY_FAILED';

/* BRANCH  */
export const GET_BRANCHES = 'GET_BRANCHES';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILED = 'GET_BRANCHES_FAILED';

export const SUBMIT_BRANCH_FORM = 'SUBMIT_BRANCH_FORM';
export const SUBMIT_BRANCH_FORM_SUCCESS = 'SUBMIT_BRANCH_FORM_SUCCESS';
export const SUBMIT_BRANCH_FORM_FAILED = 'SUBMIT_BRANCH_FORM_FAILED';

export const DELETE_BRANCH = 'DELETE_BRANCH';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILED = 'DELETE_BRANCH_FAILED';

/* SERVICE  */
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILED = 'GET_SERVICES_FAILED';

export const SUBMIT_SERVICE_FORM = 'SUBMIT_SERVICE_FORM';
export const SUBMIT_SERVICE_FORM_SUCCESS = 'SUBMIT_SERVICE_FORM_SUCCESS';
export const SUBMIT_SERVICE_FORM_FAILED = 'SUBMIT_SERVICE_FORM_FAILED';

export const DELETE_SERVICE = 'DELETE_SERVICE';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILED = 'DELETE_BRANCH_FAILED';

/* USER  */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const SUBMIT_USER_FORM = 'SUBMIT_USER_FORM';
export const SUBMIT_USER_FORM_SUCCESS = 'SUBMIT_USER_FORM_SUCCESS';
export const SUBMIT_USER_FORM_FAILED = 'SUBMIT_USER_FORM_FAILED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

/* CATEGORY  */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';

export const SUBMIT_CATEGORY_FORM = 'SUBMIT_CATEGORY_FORM';
export const SUBMIT_CATEGORY_FORM_SUCCESS = 'SUBMIT_CATEGORY_FORM_SUCCESS';
export const SUBMIT_CATEGORY_FORM_FAILED = 'SUBMIT_CATEGORY_FORM_FAILED';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';

/* PARTNER  */
export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAILED = 'GET_PARTNERS_FAILED';

export const SUBMIT_PARTNER_FORM = 'SUBMIT_PARTNER_FORM';
export const SUBMIT_PARTNER_FORM_SUCCESS = 'SUBMIT_PARTNER_FORM_SUCCESS';
export const SUBMIT_PARTNER_FORM_FAILED = 'SUBMIT_PARTNER_FORM_FAILED';

export const DELETE_PARTNER = 'DELETE_PARTNER';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const DELETE_PARTNER_FAILED = 'DELETE_PARTNER_FAILED';

/* DEAL  */
export const GET_DEALS = 'GET_DEALS';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_FAILED = 'GET_DEALS_FAILED';

export const SUBMIT_DEAL_FORM = 'SUBMIT_DEAL_FORM';
export const SUBMIT_DEAL_FORM_SUCCESS = 'SUBMIT_DEAL_FORM_SUCCESS';
export const SUBMIT_DEAL_FORM_FAILED = 'SUBMIT_DEAL_FORM_FAILED';

export const DELETE_DEAL = 'DELETE_DEAL';
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS';
export const DELETE_DEAL_FAILED = 'DELETE_DEAL_FAILED';

/* NEWS  */
export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILED = 'GET_NEWS_FAILED';

export const SUBMIT_NEWS_FORM = 'SUBMIT_NEWS_FORM';
export const SUBMIT_NEWS_FORM_SUCCESS = 'SUBMIT_NEWS_FORM_SUCCESS';
export const SUBMIT_NEWS_FORM_FAILED = 'SUBMIT_NEWS_FORM_FAILED';

export const DELETE_NEWS = 'DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILED = 'DELETE_NEWS_FAILED';

/* ALUMNI  */
export const GET_ALUMNIS = 'GET_ALUMNIS';
export const GET_ALUMNIS_SUCCESS = 'GET_ALUMNIS_SUCCESS';
export const GET_ALUMNIS_FAILED = 'GET_ALUMNIS_FAILED';

export const GET_CURRENT_ALUMNI = 'GET_CURRENT_ALUMNI';
export const GET_CURRENT_ALUMNI_SUCCESS = 'GET_CURRENT_ALUMNI_SUCCESS';
export const GET_CURRENT_ALUMNI_FAILED = 'GET_CURRENT_ALUMNI_FAILED';

export const SUBMIT_ALUMNI_FORM = 'SUBMIT_ALUMNI_FORM';
export const SUBMIT_ALUMNI_FORM_SUCCESS = 'SUBMIT_ALUMNI_FORM_SUCCESS';
export const SUBMIT_ALUMNI_FORM_FAILED = 'SUBMIT_ALUMNI_FORM_FAILED';

export const DELETE_ALUMNI = 'DELETE_ALUMNI';
export const DELETE_ALUMNI_SUCCESS = 'DELETE_ALUMNI_SUCCESS';
export const DELETE_ALUMNI_FAILED = 'DELETE_ALUMNI_FAILED';

export const IMPORT_ALUMNIS = 'IMPORT_ALUMNIS';
export const IMPORT_ALUMNIS_SUCCESS = 'IMPORT_ALUMNIS_SUCCESS';
export const IMPORT_ALUMNIS_FAILED = 'IMPORT_ALUMNIS_FAILED';


/* TRANSACTION  */
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';

export const GET_TRANSACTIONS_COUNT = 'GET_TRANSACTIONS_COUNT';
export const GET_TRANSACTIONS_COUNT_SUCCESS = 'GET_TRANSACTIONS_COUNT_SUCCESS';
export const GET_TRANSACTIONS_COUNT_FAILED = 'GET_TRANSACTIONS_COUNT_FAILED';

export const SUBMIT_TRANSACTION_FORM = 'SUBMIT_TRANSACTION_FORM';
export const SUBMIT_TRANSACTION_FORM_SUCCESS = 'SUBMIT_TRANSACTION_FORM_SUCCESS';
export const SUBMIT_TRANSACTION_FORM_FAILED = 'SUBMIT_TRANSACTION_FORM_FAILED';

export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILED = 'DELETE_TRANSACTION_FAILED';

export const IMPORT_TRANSACTIONS = 'IMPORT_TRANSACTIONS';
export const IMPORT_TRANSACTIONS_SUCCESS = 'IMPORT_TRANSACTIONS_SUCCESS';
export const IMPORT_TRANSACTIONS_FAILED = 'IMPORT_TRANSACTIONS_FAILED';

export const GET_CURRENT_TRANSACTION = 'GET_CURRENT_TRANSACTION';
export const GET_CURRENT_TRANSACTION_SUCCESS = 'GET_CURRENT_TRANSACTION_SUCCESS';
export const GET_CURRENT_TRANSACTION_FAILED = 'GET_CURRENT_TRANSACTION_FAILED';

/* AUTH USER  */
export const SUBMIT_CHANGE_PASSWORD_FORM = 'SUBMIT_CHANGE_PASSWORD_FORM';
export const SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS = 'SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS';
export const SUBMIT_CHANGE_PASSWORD_FORM_FAILED = 'SUBMIT_CHANGE_PASSWORD_FORM_FAILED';

/* DASHBOARD  */
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILED = 'GET_DASHBOARD_DATA_FAILED';

/* PRODUCTS  */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const SUBMIT_PRODUCT_FORM = 'SUBMIT_PRODUCT_FORM';
export const SUBMIT_PRODUCT_FORM_SUCCESS = 'SUBMIT_PRODUCT_FORM_SUCCESS';
export const SUBMIT_PRODUCT_FORM_FAILED = 'SUBMIT_PRODUCT_FORM_FAILED';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

/* CATEGORY  */
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_FAILED = 'GET_PRODUCT_CATEGORIES_FAILED';

export const SUBMIT_PRODUCT_CATEGORY_FORM = 'SUBMIT_PRODUCT_CATEGORY_FORM';
export const SUBMIT_PRODUCT_CATEGORY_FORM_SUCCESS = 'SUBMIT_PRODUCT_CATEGORY_FORM_SUCCESS';
export const SUBMIT_PRODUCT_CATEGORY_FORM_FAILED = 'SUBMIT_PRODUCT_CATEGORY_FORM_FAILED';

export const DELETE_PRODUCT_CATEGORY = 'DELETE_PRODUCT_CATEGORY';
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'DELETE_PRODUCT_CATEGORY_SUCCESS';
export const DELETE_PRODUCT_CATEGORY_FAILED = 'DELETE_PRODUCT_CATEGORY_FAILED';


/* AFFILIATE  */
export const GET_AFFILIATES = 'GET_AFFILIATES';
export const GET_AFFILIATES_SUCCESS = 'GET_AFFILIATES_SUCCESS';
export const GET_AFFILIATES_FAILED = 'GET_AFFILIATES_FAILED';

export const SUBMIT_AFFILIATE_FORM = 'SUBMIT_AFFILIATE_FORM';
export const SUBMIT_AFFILIATE_FORM_SUCCESS = 'SUBMIT_AFFILIATE_FORM_SUCCESS';
export const SUBMIT_AFFILIATE_FORM_FAILED = 'SUBMIT_AFFILIATE_FORM_FAILED';

export const DELETE_AFFILIATE = 'DELETE_AFFILIATE';
export const DELETE_AFFILIATE_SUCCESS = 'DELETE_AFFILIATE_SUCCESS';
export const DELETE_AFFILIATE_FAILED = 'DELETE_AFFILIATE_FAILED';

export const GET_CURRENT_AFFILIATE = 'GET_CURRENT_AFFILIATE';
export const GET_CURRENT_AFFILIATE_SUCCESS = 'GET_CURRENT_AFFILIATE_SUCCESS';
export const GET_CURRENT_AFFILIATE_FAILED = 'GET_CURRENT_AFFILIATE_FAILED';


/* REFFERAL  */
export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_FAILED = 'GET_REFERRALS_FAILED';

export const GET_REFERRALS_COUNT = 'GET_REFERRALS_COUNT';
export const GET_REFERRALS_COUNT_SUCCESS = 'GET_REFERRALS_COUNT_SUCCESS';
export const GET_REFERRALS_COUNT_FAILED = 'GET_REFERRALS_COUNT_FAILED';

export const SUBMIT_REFERRAL_FORM = 'SUBMIT_REFERRAL_FORM';
export const SUBMIT_REFERRAL_FORM_SUCCESS = 'SUBMIT_REFERRAL_FORM_SUCCESS';
export const SUBMIT_REFERRAL_FORM_FAILED = 'SUBMIT_REFERRAL_FORM_FAILED';

export const DELETE_REFERRAL = 'DELETE_REFERRAL';
export const DELETE_REFERRAL_SUCCESS = 'DELETE_REFERRAL_SUCCESS';
export const DELETE_REFERRAL_FAILED = 'DELETE_REFERRAL_FAILED';

export const GET_CURRENT_REFERRAL = 'GET_CURRENT_REFERRAL';
export const GET_CURRENT_REFERRAL_SUCCESS = 'GET_CURRENT_REFERRAL_SUCCESS';
export const GET_CURRENT_REFERRAL_FAILED = 'GET_CURRENT_REFERRAL_FAILED';

/* TICKETS  */
export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILED = 'GET_TICKETS_FAILED';

export const SUBMIT_TICKET_FORM = 'SUBMIT_TICKET_FORM';
export const SUBMIT_TICKET_FORM_SUCCESS = 'SUBMIT_TICKET_FORM_SUCCESS';
export const SUBMIT_TICKET_FORM_FAILED = 'SUBMIT_TICKET_FORM_FAILED';

export const DELETE_TICKET = 'DELETE_TICKET';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_FAILED = 'DELETE_TICKET_FAILED';

export const GET_CURRENT_TICKET = 'GET_CURRENT_TICKET';
export const GET_CURRENT_TICKET_SUCCESS = 'GET_CURRENT_TICKET_SUCCESS';
export const GET_CURRENT_TICKET_FAILED = 'GET_CURRENT_TICKET_FAILED';

/* TICKET COMMENTS  */
export const GET_TICKET_COMMENTS = 'GET_TICKET_COMMENTS';
export const GET_TICKET_COMMENTS_SUCCESS = 'GET_TICKET_COMMENTS_SUCCESS';
export const GET_TICKET_COMMENTS_FAILED = 'GET_TICKET_COMMENTS_FAILED';

export const SUBMIT_TICKET_COMMENT_FORM = 'SUBMIT_TICKET_COMMENT_FORM';
export const SUBMIT_TICKET_COMMENT_FORM_SUCCESS = 'SUBMIT_TICKET_COMMENT_FORM_SUCCESS';
export const SUBMIT_TICKET_COMMENT_FORM_FAILED = 'SUBMIT_TICKET_COMMENT_FORM_FAILED';

export const DELETE_TICKET_COMMENT = 'DELETE_TICKET_COMMENT';
export const DELETE_TICKET_COMMENT_SUCCESS = 'DELETE_TICKET_COMMENT_SUCCESS';
export const DELETE_TICKET_COMMENT_FAILED = 'DELETE_TICKET_COMMENT_FAILED';

/* REFERRAL COMMENTS  */
export const GET_REFERRAL_COMMENTS = 'GET_REFERRAL_COMMENTS';
export const GET_REFERRAL_COMMENTS_SUCCESS = 'GET_REFERRAL_COMMENTS_SUCCESS';
export const GET_REFERRAL_COMMENTS_FAILED = 'GET_REFERRAL_COMMENTS_FAILED';

export const SUBMIT_REFERRAL_COMMENT_FORM = 'SUBMIT_REFERRAL_COMMENT_FORM';
export const SUBMIT_REFERRAL_COMMENT_FORM_SUCCESS = 'SUBMIT_REFERRAL_COMMENT_FORM_SUCCESS';
export const SUBMIT_REFERRAL_COMMENT_FORM_FAILED = 'SUBMIT_REFERRAL_COMMENT_FORM_FAILED';

export const DELETE_REFERRAL_COMMENT = 'DELETE_REFERRAL_COMMENT';
export const DELETE_REFERRAL_COMMENT_SUCCESS = 'DELETE_REFERRAL_COMMENT_SUCCESS';
export const DELETE_REFERRAL_COMMENT_FAILED = 'DELETE_REFERRAL_COMMENT_FAILED';

/* TRANSACTION COMMENTS  */
export const GET_TRANSACTION_COMMENTS = 'GET_TRANSACTION_COMMENTS';
export const GET_TRANSACTION_COMMENTS_SUCCESS = 'GET_TRANSACTION_COMMENTS_SUCCESS';
export const GET_TRANSACTION_COMMENTS_FAILED = 'GET_TRANSACTION_COMMENTS_FAILED';

export const SUBMIT_TRANSACTION_COMMENT_FORM = 'SUBMIT_TRANSACTION_COMMENT_FORM';
export const SUBMIT_TRANSACTION_COMMENT_FORM_SUCCESS = 'SUBMIT_TRANSACTION_COMMENT_FORM_SUCCESS';
export const SUBMIT_TRANSACTION_COMMENT_FORM_FAILED = 'SUBMIT_TRANSACTION_COMMENT_FORM_FAILED';

export const DELETE_TRANSACTION_COMMENT = 'DELETE_TRANSACTION_COMMENT';
export const DELETE_TRANSACTION_COMMENT_SUCCESS = 'DELETE_TRANSACTION_COMMENT_SUCCESS';
export const DELETE_TRANSACTION_COMMENT_FAILED = 'DELETE_TRANSACTION_COMMENT_FAILED';