import {
    GET_TRANSACTION_COMMENTS,
    GET_TRANSACTION_COMMENTS_SUCCESS,
    GET_TRANSACTION_COMMENTS_FAILED,

    SUBMIT_TRANSACTION_COMMENT_FORM,
    SUBMIT_TRANSACTION_COMMENT_FORM_SUCCESS,
    SUBMIT_TRANSACTION_COMMENT_FORM_FAILED,

    DELETE_TRANSACTION_COMMENT,
    DELETE_TRANSACTION_COMMENT_SUCCESS,
    DELETE_TRANSACTION_COMMENT_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllTransactionComments = ({offset, limit, filter = {}}) => ({
    type: GET_TRANSACTION_COMMENTS,
    payload: { offset, limit, filter }
});

export const getAllTransactionCommentsSuccess = ({total, data}) => ({
    type: GET_TRANSACTION_COMMENTS_SUCCESS,
    payload: {total, data}
});

export const getAllTransactionCommentsFailed = (error) => ({
    type: GET_TRANSACTION_COMMENTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTransactionCommentForm = (item) => ({
    type: SUBMIT_TRANSACTION_COMMENT_FORM,
    payload: item
});

export const submitTransactionCommentFormSuccess = (item) => ({
    type: SUBMIT_TRANSACTION_COMMENT_FORM_SUCCESS,
    payload: item
});

export const submitTransactionCommentFormFailed = (error) => ({
    type: SUBMIT_TRANSACTION_COMMENT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteTransactionComment = (id) => ({
    type: DELETE_TRANSACTION_COMMENT,
    payload: id
});

export const deleteTransactionCommentSuccess = (id) => ({
    type: DELETE_TRANSACTION_COMMENT_SUCCESS,
    payload: id
});

export const deleteTransactionCommentFailed = (error) => ({
    type: DELETE_TRANSACTION_COMMENT_FAILED,
    payload: error
});
