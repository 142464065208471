import {
    GET_AFFILIATES,
    GET_AFFILIATES_SUCCESS,
    GET_AFFILIATES_FAILED,

    SUBMIT_AFFILIATE_FORM,
    SUBMIT_AFFILIATE_FORM_SUCCESS,
    SUBMIT_AFFILIATE_FORM_FAILED,

    DELETE_AFFILIATE,
    DELETE_AFFILIATE_SUCCESS,
    DELETE_AFFILIATE_FAILED,

    GET_CURRENT_AFFILIATE,
    GET_CURRENT_AFFILIATE_SUCCESS,
    GET_CURRENT_AFFILIATE_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllAffiliates = ({offset, limit, filter = {}}) => ({
    type: GET_AFFILIATES,
    payload: { offset, limit, filter }
});

export const getAllAffiliatesSuccess = ({total, data}) => ({
    type: GET_AFFILIATES_SUCCESS,
    payload: {total, data}
});

export const getAllAffiliatesFailed = (error) => ({
    type: GET_AFFILIATES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitAffiliateForm = (country) => ({
    type: SUBMIT_AFFILIATE_FORM,
    payload: country
});

export const submitAffiliateFormSuccess = (item) => ({
    type: SUBMIT_AFFILIATE_FORM_SUCCESS,
    payload: item
});

export const submitAffiliateFormFailed = (error) => ({
    type: SUBMIT_AFFILIATE_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteAffiliate = (id) => ({
    type: DELETE_AFFILIATE,
    payload: id
});

export const deleteAffiliateSuccess = (id) => ({
    type: DELETE_AFFILIATE_SUCCESS,
    payload: id
});

export const deleteAffiliateFailed = (error) => ({
    type: DELETE_AFFILIATE_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentAffiliate = (id) => ({
    type: GET_CURRENT_AFFILIATE,
    payload: id
});

export const getCurrentAffiliateSuccess = (item) => ({
    type: GET_CURRENT_AFFILIATE_SUCCESS,
    payload: item
});

export const getCurrentAffiliateFailed = (error) => ({
    type: GET_CURRENT_AFFILIATE_FAILED,
    payload: error
});