import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_ALUMNIS, 
    GET_CURRENT_ALUMNI,
    SUBMIT_ALUMNI_FORM,
    DELETE_ALUMNI,
    IMPORT_ALUMNIS
} from '../../constants/actionTypes';


import {
    getAllAlumnisSuccess,
    getAllAlumnisFailed,

    getCurrentAlumniSuccess,
    getCurrentAlumniFailed,

    submitAlumniFormSuccess,
    submitAlumniFormFailed,

    deleteAlumniSuccess,
    deleteAlumniFailed,

    importAlumnisSuccess,
    importAlumnisFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/alumnis`;

function* getAllAlumnis({ payload: { offset = 0, limit = 20, filter = { } } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        if(filter.isApproved) {
            url = `${url}&isApproved=true`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllAlumnisSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllAlumnisFailed(message));
    }
}

function* getCurrentAlumni({ payload: id }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}/${id}`;
        const {data} = yield call(fetchJSON, url, options);
        
        yield put(getCurrentAlumniSuccess(data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentAlumniFailed(message));
    }
}

function* submitAlumni({ payload: alumni }) {
    const options = {
        body: JSON.stringify(alumni),
        method: alumni.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(alumni.id) {
            url = `${apiUrl}/${alumni.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitAlumniFormSuccess(response.data));
        }
        else {
            yield put(submitAlumniFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitAlumniFormFailed(message));
    }
}

function* deleteAlumni({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteAlumniSuccess(id));
        }
        else {
            yield put(deleteAlumniFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteAlumniFailed(message));
    }
}


function* importAlumnis({ payload: file }) {
    const options = {
        body: JSON.stringify({file}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}/import`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(importAlumnisSuccess());
        }
        else {
            yield put(importAlumnisFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(importAlumnisFailed(message));
    }
}


export function* watchGetAllAlumnis() {
    yield takeEvery(GET_ALUMNIS, getAllAlumnis);
}
export function* watchGetCurrentAlumni() {
    yield takeEvery(GET_CURRENT_ALUMNI, getCurrentAlumni);
}
export function* watchSubmitAlumniForm() {
    yield takeEvery(SUBMIT_ALUMNI_FORM, submitAlumni);
}
export function* watchDeleteAlumni() {
    yield takeEvery(DELETE_ALUMNI, deleteAlumni);
}
export function* watchImportAlumnis() {
    yield takeEvery(IMPORT_ALUMNIS, importAlumnis);
}

function* alumniSaga() {
    yield all([
        fork(watchGetAllAlumnis),
        fork(watchGetCurrentAlumni),
        fork(watchSubmitAlumniForm),
        fork(watchDeleteAlumni),
        fork(watchImportAlumnis)
    ]);
}

export default alumniSaga;