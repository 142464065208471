import { getLoggedInUser } from './authUtils'; 


const fetchJSON = (url, options = {}) => {
    const user = getLoggedInUser();
    if(user) {
        options.headers =  {
            ...options.headers,
            'x-access-token': user.token
          };
    } 
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

const getApiEndPoint = () => {
    const { hostname } = window.location;
    let serverUrl;
  
    switch (hostname) {
      case 'localhost':
        serverUrl = 'http://localhost:8182';
        break;
      case 'portal.eevsalumni.com':
        serverUrl = 'https://api.eevsalumni.com';
        break;
      default:
        serverUrl = 'localhost:8182';
    }
  
    return serverUrl;
  }
  
  const apiRootEndpoint = getApiEndPoint();

  const getAffiliateUrl = () => {
    const { hostname } = window.location;
    let url;
  
    switch (hostname) {
      case 'localhost':
        url = 'http://localhost:3002';
        break;
      case 'portal.eevsalumni.com':
          url = 'https://affiliate.experteducation.com';
          break;
      default:
    }
  
    return url;
  }
  


  export { fetchJSON, apiRootEndpoint, getAffiliateUrl  };