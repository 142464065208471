import {
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILED,

    SUBMIT_CATEGORY_FORM,
    SUBMIT_CATEGORY_FORM_SUCCESS,
    SUBMIT_CATEGORY_FORM_FAILED,

    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    categories: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const CategoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CATEGORIES:
            return { ...state, loading: true };
        case GET_CATEGORIES_SUCCESS:
            return { ...state, categories: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_CATEGORIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_CATEGORY_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_CATEGORY_FORM_SUCCESS:
            const alreadyExists = state.categories.find((category) => category.id === action.payload.id);
            let categories;
            let total = state.total;
            if (!alreadyExists) {
                categories = [action.payload, ...state.categories];
                total += 1;
            } else {
                categories = state.categories.map((category) => {
                if (category.id === action.payload.id) {
                  return action.payload;
                }
                return category;
              });
            }
            return { ...state, total,  categories, submittingForm: false };
        case SUBMIT_CATEGORY_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_CATEGORY:
            return { ...state, error: null, deleting: true };
        case DELETE_CATEGORY_SUCCESS:
            return { ...state, total: state.total - 1, categories: state.categories.filter(branch => branch.id !== action.payload) , deleting: false };
        case DELETE_CATEGORY_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default CategoryReducer;