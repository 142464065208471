import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_BRANCHES, 
    SUBMIT_BRANCH_FORM,
    DELETE_BRANCH
} from '../../constants/actionTypes';


import {
    getAllBranchesSuccess,
    getAllBranchesFailed,

    submitBranchFormSuccess,
    submitBranchFormFailed,

    deleteBranchSuccess,
    deleteBranchFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/branches`;

function* getAllBranches({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllBranchesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllBranchesFailed(message));
    }
}

function* submitBranch({ payload: branch }) {
    const options = {
        body: JSON.stringify(branch),
        method: branch.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(branch.id) {
            url = `${apiUrl}/${branch.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitBranchFormSuccess(response.data));
        }
        else {
            yield put(submitBranchFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitBranchFormFailed(message));
    }
}

function* deleteBranch({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteBranchSuccess(id));
        }
        else {
            yield put(deleteBranchFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteBranchFailed(message));
    }
}


export function* watchGetAllBranches() {
    yield takeEvery(GET_BRANCHES, getAllBranches);
}
export function* watchSubmitBranchForm() {
    yield takeEvery(SUBMIT_BRANCH_FORM, submitBranch);
}
export function* watchDeleteBranch() {
    yield takeEvery(DELETE_BRANCH, deleteBranch);
}

function* branchSaga() {
    yield all([
        fork(watchGetAllBranches),
        fork(watchSubmitBranchForm),
        fork(watchDeleteBranch),
    ]);
}

export default branchSaga;