import {
    GET_ALUMNIS,
    GET_ALUMNIS_SUCCESS,
    GET_ALUMNIS_FAILED,

    GET_CURRENT_ALUMNI,
    GET_CURRENT_ALUMNI_SUCCESS,
    GET_CURRENT_ALUMNI_FAILED,

    SUBMIT_ALUMNI_FORM,
    SUBMIT_ALUMNI_FORM_SUCCESS,
    SUBMIT_ALUMNI_FORM_FAILED,

    DELETE_ALUMNI,
    DELETE_ALUMNI_SUCCESS,
    DELETE_ALUMNI_FAILED,

    IMPORT_ALUMNIS,
    IMPORT_ALUMNIS_SUCCESS,
    IMPORT_ALUMNIS_FAILED
} from '../../constants/actionTypes'; 

/** GET ALL */
export const getAllAlumnis = ({ offset, limit, filter }) => ({
    type: GET_ALUMNIS,
    payload: { offset, limit, filter }
});

export const getAllAlumnisSuccess = ({total, data}) => ({
    type: GET_ALUMNIS_SUCCESS,
    payload: {total, data}
});

export const getAllAlumnisFailed = (error) => ({
    type: GET_ALUMNIS_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentAlumni = (id) => ({
    type: GET_CURRENT_ALUMNI,
    payload: id
});

export const getCurrentAlumniSuccess = (data) => ({
    type: GET_CURRENT_ALUMNI_SUCCESS,
    payload: data
});

export const getCurrentAlumniFailed = (error) => ({
    type: GET_CURRENT_ALUMNI_FAILED,
    payload: error
});



/** SUBMIT FORM */
export const submitAlumniForm = (user) => ({
    type: SUBMIT_ALUMNI_FORM,
    payload: user
});

export const submitAlumniFormSuccess = (user) => ({
    type: SUBMIT_ALUMNI_FORM_SUCCESS,
    payload: user
});

export const submitAlumniFormFailed = (error) => ({
    type: SUBMIT_ALUMNI_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteAlumni = (id) => ({
    type: DELETE_ALUMNI,
    payload: id
});

export const deleteAlumniSuccess = (id) => ({
    type: DELETE_ALUMNI_SUCCESS,
    payload: id
});

export const deleteAlumniFailed = (error) => ({
    type: DELETE_ALUMNI_FAILED,
    payload: error
});

/** IMPORT */
export const importAlumnis = ({file}) => ({
    type: IMPORT_ALUMNIS,
    payload: file
});

export const importAlumnisSuccess = () => ({
    type: IMPORT_ALUMNIS_SUCCESS
});

export const importAlumnisFailed = (error) => ({
    type: IMPORT_ALUMNIS_FAILED,
    payload: error
});