import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TICKETS, 
    SUBMIT_TICKET_FORM,
    DELETE_TICKET,
    GET_CURRENT_TICKET
} from '../../constants/actionTypes';


import {
    getAllTicketsSuccess,
    getAllTicketsFailed,

    submitTicketFormSuccess,
    submitTicketFormFailed,

    deleteTicketSuccess,
    deleteTicketFailed,

    getCurrentTicketSuccess,
    getCurrentTicketFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/tickets`; 

function* getAllTickets({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data, message} = yield call(fetchJSON, url, options);
        if(message) {
            yield put(getAllTicketsFailed(message));
        }
        else {
            yield put(getAllTicketsSuccess({total, data}));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTicketsFailed(message));
    }
}

function* submitTicket({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTicketFormSuccess(response.data));
        }
        else {
            yield put(submitTicketFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTicketFormFailed(message));
    }
}

function* deleteTicket({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteTicketSuccess(id));
        }
        else {
            yield put(deleteTicketFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTicketFailed(message));
    }
}

function* getCurrentTicket({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const appraisal = yield call(fetchJSON, url, options);
        yield put(getCurrentTicketSuccess(appraisal.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentTicketFailed(message));
    }
}



export function* watchGetAllTickets() {
    yield takeEvery(GET_TICKETS, getAllTickets);
}
export function* watchSubmitTicketForm() {
    yield takeEvery(SUBMIT_TICKET_FORM, submitTicket);
}
export function* watchDeleteTicket() {
    yield takeEvery(DELETE_TICKET, deleteTicket);
}
export function* watchGetCurrentTicket() {
    yield takeEvery(GET_CURRENT_TICKET, getCurrentTicket);
}

function* ticketSaga() {
    yield all([
        fork(watchGetAllTickets),
        fork(watchSubmitTicketForm),
        fork(watchDeleteTicket),
        fork(watchGetCurrentTicket),
    ]);
}

export default ticketSaga;