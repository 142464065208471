import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import countrySaga from './country/saga';
import branchSaga from './branch/saga';
import serviceSaga from './service/saga';
import userSaga from './user/saga';
import categorySaga from './category/saga';
import partnerSaga from './partner/saga';
import dealSaga from './deal/saga';
import newsSaga from './news/saga';
import alumniSaga from './alumni/saga';
import transactionSaga from './transaction/saga';
import authUser from './authUser/saga';
import dashboardSaga from './dashboard/saga';
import productSaga from './product/saga';
import productCategorySaga from './productCategory/saga';
import affiliateSaga from './affiliate/saga';
import referralSaga from './referral/saga';
import ticketSaga from './ticket/saga';
import ticketCommentSaga from './ticketComment/saga';
import referralCommentSaga from './referralComment/saga';
import transactionCommentSaga from './transactionComment/saga';


export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        countrySaga(),
        branchSaga(),
        serviceSaga(),
        userSaga(),
        categorySaga(),
        partnerSaga(),
        dealSaga(),
        newsSaga(),
        alumniSaga(),
        transactionSaga(),
        authUser(),
        dashboardSaga(),
        productSaga(),
        productCategorySaga(),
        affiliateSaga(),
        referralSaga(),
        ticketSaga(),
        ticketCommentSaga(),
        referralCommentSaga(),
        transactionCommentSaga()
    ]);
}
