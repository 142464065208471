import {
    GET_NEWS,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILED,

    SUBMIT_NEWS_FORM,
    SUBMIT_NEWS_FORM_SUCCESS,
    SUBMIT_NEWS_FORM_FAILED,

    DELETE_NEWS,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllNews = ({offset, limit}) => ({
    type: GET_NEWS,
    payload: { offset, limit }
});

export const getAllNewsSuccess = ({total, data}) => ({
    type: GET_NEWS_SUCCESS,
    payload: {total, data}
});

export const getAllNewsFailed = (error) => ({
    type: GET_NEWS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitNewsForm = (news) => ({
    type: SUBMIT_NEWS_FORM,
    payload: news
});

export const submitNewsFormSuccess = (news) => ({
    type: SUBMIT_NEWS_FORM_SUCCESS,
    payload: news
});

export const submitNewsFormFailed = (error) => ({
    type: SUBMIT_NEWS_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteNews = (id) => ({
    type: DELETE_NEWS,
    payload: id
});

export const deleteNewsSuccess = (id) => ({
    type: DELETE_NEWS_SUCCESS,
    payload: id
});

export const deleteNewsFailed = (error) => ({
    type: DELETE_NEWS_FAILED,
    payload: error
});