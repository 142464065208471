import {
    GET_REFERRALS,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILED,

    SUBMIT_REFERRAL_FORM,
    SUBMIT_REFERRAL_FORM_SUCCESS,
    SUBMIT_REFERRAL_FORM_FAILED,

    DELETE_REFERRAL,
    DELETE_REFERRAL_SUCCESS,
    DELETE_REFERRAL_FAILED,

    GET_REFERRALS_COUNT,
    GET_REFERRALS_COUNT_SUCCESS,
    GET_REFERRALS_COUNT_FAILED,

    GET_CURRENT_REFERRAL,
    GET_CURRENT_REFERRAL_SUCCESS,
    GET_CURRENT_REFERRAL_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    referrals: [],
    submittingForm: false,
    error: null,
    deleting: false,
    unreadCount: 0,
    currentReferral: null
};

const CountryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REFERRALS:
            return { ...state, loading: true };
        case GET_REFERRALS_SUCCESS:
            return { ...state, referrals: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_REFERRALS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_REFERRALS_COUNT:
            return { ...state, loading: true };
        case GET_REFERRALS_COUNT_SUCCESS:
            return { ...state, unreadCount: action.payload.total, loading: false, error: null };
        case GET_REFERRALS_COUNT_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_REFERRAL_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_REFERRAL_FORM_SUCCESS:
            const alreadyExists = state.referrals.find((item) => item.id === action.payload.id);
            let referrals;
            let total = state.total;
            if (!alreadyExists) {
                referrals = [action.payload, ...state.referrals];
                total += 1;
            } else {
                referrals = state.referrals.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              });
            }
            return { ...state, total,  referrals, submittingForm: false };
        case SUBMIT_REFERRAL_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_REFERRAL:
            return { ...state, error: null, deleting: true };
        case DELETE_REFERRAL_SUCCESS:
            return { ...state, total: state.total - 1, referrals: state.referrals.filter(item => item.id !== action.payload) , deleting: false };
        case DELETE_REFERRAL_FAILED:
            return { ...state, error: action.payload, deleting: false };

        case GET_CURRENT_REFERRAL:
            return { ...state, loading: true };
        case GET_CURRENT_REFERRAL_SUCCESS:
            return { ...state, currentReferral: action.payload, loading: false, error: null };
        case GET_CURRENT_REFERRAL_FAILED:
            return { ...state, error: action.payload, loading: false };
        
        
        default: return { ...state };
    }
}

export default CountryReducer;