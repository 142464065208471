import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';

// lazy load all the views
const Dashboard = React.lazy(() => import('./pages/dashboards/'));
const Countries = React.lazy(() => import('./pages/countries/'));
const Branches = React.lazy(() => import('./pages/branches/'));
const Services = React.lazy(() => import('./pages/services/'));
const Users = React.lazy(() => import('./pages/users/'));
const News = React.lazy(() => import('./pages/news/'));
const Categories = React.lazy(() => import('./pages/categories/'));
const Partners = React.lazy(() => import('./pages/partners/'));
const Deals = React.lazy(() => import('./pages/deals/'));
const Almunis = React.lazy(() => import('./pages/almunis/'));
const AlmuniDetails = React.lazy(() => import('./pages/alumniDetails/'));
const Products = React.lazy(() => import('./pages/products/'));
const ProductCategories = React.lazy(() => import('./pages/productCategories/'));
const Profile = React.lazy(() => import('./pages/profile/'));
const ChangePassword = React.lazy(() => import('./pages/changePassword/'));

const Transactions = React.lazy(() => import('./pages/transactions/'));
const TransactionDetails = React.lazy(() => import('./pages/transactionDetails/'));

const Referrals = React.lazy(() => import('./pages/referrals/'));
const ReferralDetails = React.lazy(() => import('./pages/referralDetails/'));

const Affiliates = React.lazy(() => import('./pages/affiliates/'));
const AffiliateDetails = React.lazy(() => import('./pages/affiliateDetails/'));

const Tickets = React.lazy(() => import('./pages/tickets/'));
const TicketDetails = React.lazy(() => import('./pages/ticketDetails/'));

// auth
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./pages/account/ResetPassword'));
const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    const loggedInUser = getLoggedInUser();
    // check if route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: '/' }} />
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and account
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forget-password', name: 'Forget Password', component: ForgetPassword, route: Route },
  { path: '/reset-password', name: 'Reset Password', component: ResetPassword, route: Route },
  { path: '/confirm', name: 'Confirm', component: ConfirmAccount, route: Route },

  // other pages
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/countries', name: 'Countries', component: Countries, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/branches', name: 'Branches', component: Branches, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/services', name: 'Services', component: Services, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/users', name: 'Users', component: Users, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/news', name: 'News', component: News, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/categories', name: 'Categories', component: Categories, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/partners', name: 'Partners', component: Partners, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/deals', name: 'Deals', component: Deals, route: PrivateRoute, roles: ['Super Admin'] },
  { path: '/almunis/:id', exact: true, name: 'AlmuniDetails', component: AlmuniDetails, route: PrivateRoute, roles: ['Super Admin'] },
  { path: '/almunis', exact: true, name: 'Almunis', component: Almunis, route: PrivateRoute, roles: ['Super Admin'] },
  
  { path: '/transactions/:id', exact: true, name: 'TransactionDetails', component: TransactionDetails, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/transactions', exact: true, name: 'Transactions', component: Transactions, route: PrivateRoute, roles: ['Super Admin'] },

  { path: '/products', name: 'Products', component: Products, route: PrivateRoute, roles: ['Super Admin'] },
  { path: '/product-categories', name: 'ProductCategories', component: ProductCategories, route: PrivateRoute, roles: ['Super Admin'] },
  { path: '/profile', name: 'Profile', component: Profile, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/change-password', name: 'ChangePassword', component: ChangePassword, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/affiliates', exact: true, name: 'Affiliates', component: Affiliates, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/referrals', exact: true, name: 'Referrals', component: Referrals, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/referrals/:id', exact: true, name: 'ReferralDetails', component: ReferralDetails, route: PrivateRoute, roles: ['Super Admin', 'Staff'] },
  { path: '/affiliates/:id', exact: true, name: 'AffiliateDetails', component: AffiliateDetails, route: PrivateRoute, roles: ['Super Admin'] },
  { path: '/tickets', exact: true, name: 'Tickets', component: Tickets, route: PrivateRoute, roles: ['Super Admin'] },
  { path: '/tickets/:id', exact: true, name: 'TicketDetails', component: TicketDetails, route: PrivateRoute, roles: ['Super Admin'] },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute
  },
  
]

export { routes, PrivateRoute };