import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILED,

    SUBMIT_PARTNER_FORM,
    SUBMIT_PARTNER_FORM_SUCCESS,
    SUBMIT_PARTNER_FORM_FAILED,

    DELETE_PARTNER,
    DELETE_PARTNER_SUCCESS,
    DELETE_PARTNER_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllPartners = ({offset, limit}) => ({
    type: GET_PARTNERS,
    payload: { offset, limit }
});

export const getAllPartnersSuccess = ({total, data}) => ({
    type: GET_PARTNERS_SUCCESS,
    payload: {total, data}
});

export const getAllPartnersFailed = (error) => ({
    type: GET_PARTNERS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitPartnerForm = (partner) => ({
    type: SUBMIT_PARTNER_FORM,
    payload: partner
});

export const submitPartnerFormSuccess = (partner) => ({
    type: SUBMIT_PARTNER_FORM_SUCCESS,
    payload: partner
});

export const submitPartnerFormFailed = (error) => ({
    type: SUBMIT_PARTNER_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deletePartner = (id) => ({
    type: DELETE_PARTNER,
    payload: id
});

export const deletePartnerSuccess = (id) => ({
    type: DELETE_PARTNER_SUCCESS,
    payload: id
});

export const deletePartnerFailed = (error) => ({
    type: DELETE_PARTNER_FAILED,
    payload: error
});