import {
    GET_NEWS,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILED,

    SUBMIT_NEWS_FORM,
    SUBMIT_NEWS_FORM_SUCCESS,
    SUBMIT_NEWS_FORM_FAILED,

    DELETE_NEWS,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    news: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const NewsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEWS:
            return { ...state, loading: true };
        case GET_NEWS_SUCCESS:
            return { ...state, news: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_NEWS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_NEWS_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_NEWS_FORM_SUCCESS:
            const alreadyExists = state.news.find((newsItem) => newsItem.id === action.payload.id);
            let news;
            let total = state.total;
            if (!alreadyExists) {
                news = [action.payload, ...state.news];
                total += 1;
            } else {
                news = state.news.map((newsItem) => {
                if (newsItem.id === action.payload.id) {
                  return action.payload;
                }
                return newsItem;
              });
            }
            return { ...state, total,  news, submittingForm: false };
        case SUBMIT_NEWS_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_NEWS:
            return { ...state, error: null, deleting: true };
        case DELETE_NEWS_SUCCESS:
            return { ...state, total: state.total - 1, news: state.news.filter(newsItem => newsItem.id !== action.payload) , deleting: false };
        case DELETE_NEWS_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default NewsReducer;