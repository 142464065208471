import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Country from './country/reducers';
import Branch from './branch/reducers';
import Service from './service/reducers';
import User from './user/reducers';
import Category from './category/reducers';
import Partner from './partner/reducers';
import Deal from './deal/reducers';
import News from './news/reducers';
import Alumni from './alumni/reducers';
import Transaction from './transaction/reducers';
import AuthUser from './authUser/reducers';
import Dashboard from './dashboard/reducers';
import Product from './product/reducers';
import ProductCategory from './productCategory/reducers';
import Affiliate from './affiliate/reducers';
import Referral from './referral/reducers';
import Ticket from './ticket/reducers';
import TicketComment from './ticketComment/reducers';
import ReferralComment from './referralComment/reducers';
import TransactionComment from './transactionComment/reducers';

export default combineReducers({
    Auth,
    Country,
    Branch,
    Service,
    User,
    Category,
    Partner,
    Deal,
    News,
    Alumni,
    Transaction,
    AuthUser,
    Dashboard,
    Product,
    ProductCategory,
    Affiliate,
    Referral,
    Ticket,
    TicketComment,
    ReferralComment,
    TransactionComment
});