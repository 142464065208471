import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_AFFILIATES, 
    SUBMIT_AFFILIATE_FORM,
    DELETE_AFFILIATE,
    GET_CURRENT_AFFILIATE
} from '../../constants/actionTypes';


import {
    getAllAffiliatesSuccess,
    getAllAffiliatesFailed,

    submitAffiliateFormSuccess,
    submitAffiliateFormFailed,

    deleteAffiliateSuccess,
    deleteAffiliateFailed,

    getCurrentAffiliateSuccess,
    getCurrentAffiliateFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/affiliates`; 

function* getAllAffiliates({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data, message} = yield call(fetchJSON, url, options);
        if(message) {
            yield put(getAllAffiliatesFailed(message));
        }
        else {
            yield put(getAllAffiliatesSuccess({total, data}));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllAffiliatesFailed(message));
    }
}

function* submitAffiliate({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitAffiliateFormSuccess(response.data));
        }
        else {
            yield put(submitAffiliateFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitAffiliateFormFailed(message));
    }
}

function* deleteAffiliate({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteAffiliateSuccess(id));
        }
        else {
            yield put(deleteAffiliateFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteAffiliateFailed(message));
    }
}

function* getCurrentAffiliate({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const appraisal = yield call(fetchJSON, url, options);
        yield put(getCurrentAffiliateSuccess(appraisal.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentAffiliateFailed(message));
    }
}



export function* watchGetAllAffiliates() {
    yield takeEvery(GET_AFFILIATES, getAllAffiliates);
}
export function* watchSubmitAffiliateForm() {
    yield takeEvery(SUBMIT_AFFILIATE_FORM, submitAffiliate);
}
export function* watchDeleteAffiliate() {
    yield takeEvery(DELETE_AFFILIATE, deleteAffiliate);
}
export function* watchGetCurrentAffiliate() {
    yield takeEvery(GET_CURRENT_AFFILIATE, getCurrentAffiliate);
}

function* affiliateSaga() {
    yield all([
        fork(watchGetAllAffiliates),
        fork(watchSubmitAffiliateForm),
        fork(watchDeleteAffiliate),
        fork(watchGetCurrentAffiliate),
    ]);
}

export default affiliateSaga;