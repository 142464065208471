import {
    GET_SERVICES,
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILED,

    SUBMIT_SERVICE_FORM,
    SUBMIT_SERVICE_FORM_SUCCESS,
    SUBMIT_SERVICE_FORM_FAILED,

    DELETE_SERVICE,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllServices = ({offset, limit}) => ({
    type: GET_SERVICES,
    payload: { offset, limit }
});

export const getAllServicesSuccess = ({total, data}) => ({
    type: GET_SERVICES_SUCCESS,
    payload: {total, data}
});

export const getAllServicesFailed = (error) => ({
    type: GET_SERVICES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitServiceForm = (service) => ({
    type: SUBMIT_SERVICE_FORM,
    payload: service
});

export const submitServiceFormSuccess = (service) => ({
    type: SUBMIT_SERVICE_FORM_SUCCESS,
    payload: service
});

export const submitServiceFormFailed = (error) => ({
    type: SUBMIT_SERVICE_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteService = (id) => ({
    type: DELETE_SERVICE,
    payload: id
});

export const deleteServiceSuccess = (id) => ({
    type: DELETE_SERVICE_SUCCESS,
    payload: id
});

export const deleteServiceFailed = (error) => ({
    type: DELETE_SERVICE_FAILED,
    payload: error
});