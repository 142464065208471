import {
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAILED,

    SUBMIT_TRANSACTION_FORM,
    SUBMIT_TRANSACTION_FORM_SUCCESS,
    SUBMIT_TRANSACTION_FORM_FAILED,

    DELETE_TRANSACTION,
    DELETE_TRANSACTION_SUCCESS,
    DELETE_TRANSACTION_FAILED,

    IMPORT_TRANSACTIONS,
    IMPORT_TRANSACTIONS_SUCCESS,
    IMPORT_TRANSACTIONS_FAILED,

    GET_TRANSACTIONS_COUNT,
    GET_TRANSACTIONS_COUNT_SUCCESS,
    GET_TRANSACTIONS_COUNT_FAILED,

    GET_CURRENT_TRANSACTION,
    GET_CURRENT_TRANSACTION_SUCCESS,
    GET_CURRENT_TRANSACTION_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllTransactions = ({offset, limit, filter= {}}) => ({
    type: GET_TRANSACTIONS,
    payload: { offset, limit, filter }
});

export const getAllTransactionsSuccess = ({total, data}) => ({
    type: GET_TRANSACTIONS_SUCCESS,
    payload: {total, data}
});

export const getAllTransactionsFailed = (error) => ({
    type: GET_TRANSACTIONS_FAILED,
    payload: error
});

/** GET UNREAD COUNT */
export const getTransactionsCount = ({filter = {}}) => ({
    type: GET_TRANSACTIONS_COUNT,
    payload: { filter }
});

export const getTransactionsCountSuccess = ({total}) => ({
    type: GET_TRANSACTIONS_COUNT_SUCCESS,
    payload: {total}
});

export const getTransactionsCountFailed = (error) => ({
    type: GET_TRANSACTIONS_COUNT_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTransactionForm = (transaction) => ({
    type: SUBMIT_TRANSACTION_FORM,
    payload: transaction
});

export const submitTransactionFormSuccess = (transaction) => ({
    type: SUBMIT_TRANSACTION_FORM_SUCCESS,
    payload: transaction
});

export const submitTransactionFormFailed = (error) => ({
    type: SUBMIT_TRANSACTION_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteTransaction = (id) => ({
    type: DELETE_TRANSACTION,
    payload: id
});

export const deleteTransactionSuccess = (id) => ({
    type: DELETE_TRANSACTION_SUCCESS,
    payload: id
});

export const deleteTransactionFailed = (error) => ({
    type: DELETE_TRANSACTION_FAILED,
    payload: error
});

/** IMPORT */
export const importTransactions = ({file}) => ({
    type: IMPORT_TRANSACTIONS,
    payload: file
});

export const importTransactionsSuccess = () => ({
    type: IMPORT_TRANSACTIONS_SUCCESS
});

export const importTransactionsFailed = (error) => ({
    type: IMPORT_TRANSACTIONS_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentTransaction= (id) => ({
    type: GET_CURRENT_TRANSACTION,
    payload: id
});

export const getCurrentTransactionSuccess = (item) => ({
    type: GET_CURRENT_TRANSACTION_SUCCESS,
    payload: item
});

export const getCurrentTransactionFailed = (error) => ({
    type: GET_CURRENT_TRANSACTION_FAILED,
    payload: error
});