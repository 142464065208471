import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_REFERRALS, 
    SUBMIT_REFERRAL_FORM,
    DELETE_REFERRAL,
    GET_REFERRALS_COUNT,
    GET_CURRENT_REFERRAL
} from '../../constants/actionTypes';


import {
    getAllReferralsSuccess,
    getAllReferralsFailed,

    submitReferralFormSuccess,
    submitReferralFormFailed,

    deleteReferralSuccess,
    deleteReferralFailed,

    getReferralsCountFailed,
    getReferralsCountSuccess,

    getCurrentReferralSuccess,
    getCurrentReferralFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/referrals`; 

function* getAllReferrals({ payload: { offset, limit, filter = { } } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.affiliateId) {
            url = `${url}&affiliateId=${filter.affiliateId}`;
        }
        if(filter.alumniId) {
            url = `${url}&alumniId=${filter.alumniId}`;
        }

        if(filter.query) {
            url = `${url}&query=${filter.query}`;
        }
        const {total, data, message} = yield call(fetchJSON, url, options);
        if(message) {
            yield put(getAllReferralsFailed(message));
        }
        else {
            yield put(getAllReferralsSuccess({total, data}));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllReferralsFailed(message));
    }
}

function* getReferralsCount({ payload: {filter = { } } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}/count`;
        url = `${url}?isRead=${filter.isRead}`;
        const {total, message} = yield call(fetchJSON, url, options);
        if(message) {
            yield put(getReferralsCountFailed(message));
        }
        else {
            yield put(getReferralsCountSuccess({total}));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getReferralsCountFailed(message));
    }
}

function* submitReferral({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitReferralFormSuccess(response.data));
        }
        else {
            yield put(submitReferralFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitReferralFormFailed(message));
    }
}

function* deleteReferral({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteReferralSuccess(id));
        }
        else {
            yield put(deleteReferralFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteReferralFailed(message));
    }
}


function* getCurrentReferral({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const appraisal = yield call(fetchJSON, url, options);
        yield put(getCurrentReferralSuccess(appraisal.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentReferralFailed(message));
    }
}




export function* watchGetAllReferrals() {
    yield takeEvery(GET_REFERRALS, getAllReferrals);
}
export function* watchGetReferralsCount() {
    yield takeEvery(GET_REFERRALS_COUNT, getReferralsCount);
}
export function* watchSubmitReferralForm() {
    yield takeEvery(SUBMIT_REFERRAL_FORM, submitReferral);
}
export function* watchDeleteReferral() {
    yield takeEvery(DELETE_REFERRAL, deleteReferral);
}
export function* watchGetCurrentReferral() {
    yield takeEvery(GET_CURRENT_REFERRAL, getCurrentReferral);
}

function* affiliateSaga() {
    yield all([
        fork(watchGetAllReferrals),
        fork(watchGetReferralsCount),
        fork(watchSubmitReferralForm),
        fork(watchDeleteReferral),
        fork(watchGetCurrentReferral),
    ]);
}

export default affiliateSaga;