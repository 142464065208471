import {
    GET_DEALS,
    GET_DEALS_SUCCESS,
    GET_DEALS_FAILED,

    SUBMIT_DEAL_FORM,
    SUBMIT_DEAL_FORM_SUCCESS,
    SUBMIT_DEAL_FORM_FAILED,

    DELETE_DEAL,
    DELETE_DEAL_SUCCESS,
    DELETE_DEAL_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    deals: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const DealReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DEALS:
            return { ...state, loading: true };
        case GET_DEALS_SUCCESS:
            return { ...state, deals: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_DEALS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_DEAL_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_DEAL_FORM_SUCCESS:
            const alreadyExists = state.deals.find((deal) => deal.id === action.payload.id);
            let deals;
            let total = state.total;
            if (!alreadyExists) {
                deals = [action.payload, ...state.deals];
                total += 1;
            } else {
                deals = state.deals.map((deal) => {
                if (deal.id === action.payload.id) {
                  return action.payload;
                }
                return deal;
              });
            }
            return { ...state, total,  deals, submittingForm: false };
        case SUBMIT_DEAL_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_DEAL:
            return { ...state, error: null, deleting: true };
        case DELETE_DEAL_SUCCESS:
            return { ...state, total: state.total - 1, deals: state.deals.filter(deal => deal.id !== action.payload) , deleting: false };
        case DELETE_DEAL_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default DealReducer;