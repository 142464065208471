import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_SERVICES, 
    SUBMIT_SERVICE_FORM,
    DELETE_SERVICE
} from '../../constants/actionTypes';


import {
    getAllServicesSuccess,
    getAllServicesFailed,

    submitServiceFormSuccess,
    submitServiceFormFailed,

    deleteServiceSuccess,
    deleteServiceFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/services`;

function* getAllServices({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllServicesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllServicesFailed(message));
    }
}

function* submitService({ payload: service }) {
    const options = {
        body: JSON.stringify(service),
        method: service.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(service.id) {
            url = `${apiUrl}/${service.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitServiceFormSuccess(response.data));
        }
        else {
            yield put(submitServiceFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitServiceFormFailed(message));
    }
}

function* deleteService({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteServiceSuccess(id));
        }
        else {
            yield put(deleteServiceFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteServiceFailed(message));
    }
}


export function* watchGetAllServices() {
    yield takeEvery(GET_SERVICES, getAllServices);
}
export function* watchSubmitServiceForm() {
    yield takeEvery(SUBMIT_SERVICE_FORM, submitService);
}
export function* watchDeleteService() {
    yield takeEvery(DELETE_SERVICE, deleteService);
}

function* serviceSaga() {
    yield all([
        fork(watchGetAllServices),
        fork(watchSubmitServiceForm),
        fork(watchDeleteService),
    ]);
}

export default serviceSaga;