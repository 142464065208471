import {
    GET_REFERRALS,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILED,

    SUBMIT_REFERRAL_FORM,
    SUBMIT_REFERRAL_FORM_SUCCESS,
    SUBMIT_REFERRAL_FORM_FAILED,

    DELETE_REFERRAL,
    DELETE_REFERRAL_SUCCESS,
    DELETE_REFERRAL_FAILED,

    GET_REFERRALS_COUNT,
    GET_REFERRALS_COUNT_SUCCESS,
    GET_REFERRALS_COUNT_FAILED,

    GET_CURRENT_REFERRAL,
    GET_CURRENT_REFERRAL_SUCCESS,
    GET_CURRENT_REFERRAL_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllReferrals = ({offset, limit, filter = {}}) => ({
    type: GET_REFERRALS,
    payload: { offset, limit, filter }
});

export const getAllReferralsSuccess = ({total, data}) => ({
    type: GET_REFERRALS_SUCCESS,
    payload: {total, data}
});

export const getAllReferralsFailed = (error) => ({
    type: GET_REFERRALS_FAILED,
    payload: error
});

/** GET UNREAD COUNT */
export const getReferralsCount = ({filter = {}}) => ({
    type: GET_REFERRALS_COUNT,
    payload: { filter }
});

export const getReferralsCountSuccess = ({total}) => ({
    type: GET_REFERRALS_COUNT_SUCCESS,
    payload: {total}
});

export const getReferralsCountFailed = (error) => ({
    type: GET_REFERRALS_COUNT_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitReferralForm = (country) => ({
    type: SUBMIT_REFERRAL_FORM,
    payload: country
});

export const submitReferralFormSuccess = (item) => ({
    type: SUBMIT_REFERRAL_FORM_SUCCESS,
    payload: item
});

export const submitReferralFormFailed = (error) => ({
    type: SUBMIT_REFERRAL_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteReferral = (id) => ({
    type: DELETE_REFERRAL,
    payload: id
});

export const deleteReferralSuccess = (id) => ({
    type: DELETE_REFERRAL_SUCCESS,
    payload: id
});

export const deleteReferralFailed = (error) => ({
    type: DELETE_REFERRAL_FAILED,
    payload: error
});

/** GET CURRENT */
export const getCurrentReferral= (id) => ({
    type: GET_CURRENT_REFERRAL,
    payload: id
});

export const getCurrentReferralSuccess = (item) => ({
    type: GET_CURRENT_REFERRAL_SUCCESS,
    payload: item
});

export const getCurrentReferralFailed = (error) => ({
    type: GET_CURRENT_REFERRAL_FAILED,
    payload: error
});