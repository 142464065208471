import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_DEALS, 
    SUBMIT_DEAL_FORM,
    DELETE_DEAL
} from '../../constants/actionTypes';


import {
    getAllDealsSuccess,
    getAllDealsFailed,

    submitDealFormSuccess,
    submitDealFormFailed,

    deleteDealSuccess,
    deleteDealFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/deals`;

function* getAllDeals({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllDealsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllDealsFailed(message));
    }
}

function* submitDeal({ payload: deal }) {
    const options = {
        body: JSON.stringify(deal),
        method: deal.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(deal.id) {
            url = `${apiUrl}/${deal.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitDealFormSuccess(response.data));
        }
        else {
            yield put(submitDealFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitDealFormFailed(message));
    }
}

function* deleteDeal({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteDealSuccess(id));
        }
        else {
            yield put(deleteDealFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteDealFailed(message));
    }
}


export function* watchGetAllDeals() {
    yield takeEvery(GET_DEALS, getAllDeals);
}
export function* watchSubmitDealForm() {
    yield takeEvery(SUBMIT_DEAL_FORM, submitDeal);
}
export function* watchDeleteDeal() {
    yield takeEvery(DELETE_DEAL, deleteDeal);
}

function* serviceSaga() {
    yield all([
        fork(watchGetAllDeals),
        fork(watchSubmitDealForm),
        fork(watchDeleteDeal),
    ]);
}

export default serviceSaga;