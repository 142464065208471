import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_COUNTRY, 
    SUBMIT_COUNTRY_FORM,
    DELETE_COUNTRY
} from '../../constants/actionTypes';


import {
    getAllCountriesSuccess,
    getAllCountriesFailed,

    submitCountryFormSuccess,
    submitCountryFormFailed,

    deleteCountrySuccess,
    deleteCountryFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/countries`;

function* getAllCountries({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllCountriesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllCountriesFailed(message));
    }
}

function* submitCountry({ payload: country }) {
    const options = {
        body: JSON.stringify(country),
        method: country.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(country.id) {
            url = `${apiUrl}/${country.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitCountryFormSuccess(response.data));
        }
        else {
            yield put(submitCountryFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitCountryFormFailed(message));
    }
}

function* deleteCountry({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteCountrySuccess(id));
        }
        else {
            yield put(deleteCountryFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteCountryFailed(message));
    }
}


export function* watchGetAllCountries() {
    yield takeEvery(GET_COUNTRY, getAllCountries);
}
export function* watchSubmitCountryForm() {
    yield takeEvery(SUBMIT_COUNTRY_FORM, submitCountry);
}
export function* watchDeleteCountry() {
    yield takeEvery(DELETE_COUNTRY, deleteCountry);
}

function* countrySaga() {
    yield all([
        fork(watchGetAllCountries),
        fork(watchSubmitCountryForm),
        fork(watchDeleteCountry),
    ]);
}

export default countrySaga;