import {
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_CATEGORIES_SUCCESS,
    GET_PRODUCT_CATEGORIES_FAILED,

    SUBMIT_PRODUCT_CATEGORY_FORM,
    SUBMIT_PRODUCT_CATEGORY_FORM_SUCCESS,
    SUBMIT_PRODUCT_CATEGORY_FORM_FAILED,

    DELETE_PRODUCT_CATEGORY,
    DELETE_PRODUCT_CATEGORY_SUCCESS,
    DELETE_PRODUCT_CATEGORY_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    productCategories: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const CategoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCT_CATEGORIES:
            return { ...state, loading: true };
        case GET_PRODUCT_CATEGORIES_SUCCESS:
            return { ...state, productCategories: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_PRODUCT_CATEGORIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_PRODUCT_CATEGORY_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_PRODUCT_CATEGORY_FORM_SUCCESS:
            const alreadyExists = state.productCategories.find((category) => category.id === action.payload.id);
            let productCategories;
            let total = state.total;
            if (!alreadyExists) {
                productCategories = [action.payload, ...state.productCategories];
                total += 1;
            } else {
                productCategories = state.productCategories.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              });
            }
            return { ...state, total,  productCategories, submittingForm: false };
        case SUBMIT_PRODUCT_CATEGORY_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_PRODUCT_CATEGORY:
            return { ...state, error: null, deleting: true };
        case DELETE_PRODUCT_CATEGORY_SUCCESS:
            return { ...state, total: state.total - 1, productCategories: state.productCategories.filter(branch => branch.id !== action.payload) , deleting: false };
        case DELETE_PRODUCT_CATEGORY_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default CategoryReducer;