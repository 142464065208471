import {
    GET_SERVICES,
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILED,

    SUBMIT_SERVICE_FORM,
    SUBMIT_SERVICE_FORM_SUCCESS,
    SUBMIT_SERVICE_FORM_FAILED,

    DELETE_SERVICE,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    services: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const ServiceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SERVICES:
            return { ...state, loading: true };
        case GET_SERVICES_SUCCESS:
            return { ...state, services: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_SERVICES_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_SERVICE_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_SERVICE_FORM_SUCCESS:
            const alreadyExists = state.services.find((service) => service.id === action.payload.id);
            let services;
            let total = state.total;
            if (!alreadyExists) {
                services = [action.payload, ...state.services];
                total += 1;
            } else {
                services = state.services.map((service) => {
                if (service.id === action.payload.id) {
                  return action.payload;
                }
                return service;
              });
            }
            return { ...state, total,  services, submittingForm: false };
        case SUBMIT_SERVICE_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_SERVICE:
            return { ...state, error: null, deleting: true };
        case DELETE_SERVICE_SUCCESS:
            return { ...state, total: state.total - 1, services: state.services.filter(branch => branch.id !== action.payload) , deleting: false };
        case DELETE_SERVICE_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default ServiceReducer;