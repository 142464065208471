import {
    GET_REFERRAL_COMMENTS,
    GET_REFERRAL_COMMENTS_SUCCESS,
    GET_REFERRAL_COMMENTS_FAILED,

    SUBMIT_REFERRAL_COMMENT_FORM,
    SUBMIT_REFERRAL_COMMENT_FORM_SUCCESS,
    SUBMIT_REFERRAL_COMMENT_FORM_FAILED,

    DELETE_REFERRAL_COMMENT,
    DELETE_REFERRAL_COMMENT_SUCCESS,
    DELETE_REFERRAL_COMMENT_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllReferralComments = ({offset, limit, filter = {}}) => ({
    type: GET_REFERRAL_COMMENTS,
    payload: { offset, limit, filter }
});

export const getAllReferralCommentsSuccess = ({total, data}) => ({
    type: GET_REFERRAL_COMMENTS_SUCCESS,
    payload: {total, data}
});

export const getAllReferralCommentsFailed = (error) => ({
    type: GET_REFERRAL_COMMENTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitReferralCommentForm = (item) => ({
    type: SUBMIT_REFERRAL_COMMENT_FORM,
    payload: item
});

export const submitReferralCommentFormSuccess = (item) => ({
    type: SUBMIT_REFERRAL_COMMENT_FORM_SUCCESS,
    payload: item
});

export const submitReferralCommentFormFailed = (error) => ({
    type: SUBMIT_REFERRAL_COMMENT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteReferralComment = (id) => ({
    type: DELETE_REFERRAL_COMMENT,
    payload: id
});

export const deleteReferralCommentSuccess = (id) => ({
    type: DELETE_REFERRAL_COMMENT_SUCCESS,
    payload: id
});

export const deleteReferralCommentFailed = (error) => ({
    type: DELETE_REFERRAL_COMMENT_FAILED,
    payload: error
});
