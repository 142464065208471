import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_PRODUCT_CATEGORIES, 
    SUBMIT_PRODUCT_CATEGORY_FORM,
    DELETE_PRODUCT_CATEGORY,
} from '../../constants/actionTypes';


import {
    getAllProductCategoriesSuccess,
    getAllProductCategoriesFailed,

    submitProductCategoryFormSuccess,
    submitProductCategoryFormFailed,

    deleteProductCategorySuccess,
    deleteProductCategoryFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/product-categories`;

function* getAllProductCategories({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllProductCategoriesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllProductCategoriesFailed(message));
    }
}

function* submitProductCategory({ payload: productCategory }) {
    const options = {
        body: JSON.stringify(productCategory),
        method: productCategory.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(productCategory.id) {
            url = `${apiUrl}/${productCategory.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitProductCategoryFormSuccess(response.data));
        }
        else {
            yield put(submitProductCategoryFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitProductCategoryFormFailed(message));
    }
}

function* deleteProductCategory({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteProductCategorySuccess(id));
        }
        else {
            yield put(deleteProductCategoryFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteProductCategoryFailed(message));
    }
}


export function* watchGetAllProductCategories() {
    yield takeEvery(GET_PRODUCT_CATEGORIES, getAllProductCategories);
}
export function* watchSubmitProductCategoryForm() {
    yield takeEvery(SUBMIT_PRODUCT_CATEGORY_FORM, submitProductCategory);
}
export function* watchDeleteProductCategory() {
    yield takeEvery(DELETE_PRODUCT_CATEGORY, deleteProductCategory);
}

function* serviceSaga() {
    yield all([
        fork(watchGetAllProductCategories),
        fork(watchSubmitProductCategoryForm),
        fork(watchDeleteProductCategory),
    ]);
}

export default serviceSaga;