import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_PRODUCTS, 
    SUBMIT_PRODUCT_FORM,
    DELETE_PRODUCT
} from '../../constants/actionTypes';


import {
    getAllProductsSuccess,
    getAllProductsFailed,

    submitProductFormSuccess,
    submitProductFormFailed,

    deleteProductSuccess,
    deleteProductFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/products`;

function* getAllProducts({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllProductsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllProductsFailed(message));
    }
}

function* submitProduct({ payload: product }) {
    const options = {
        body: JSON.stringify(product),
        method: product.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(product.id) {
            url = `${apiUrl}/${product.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitProductFormSuccess(response.data));
        }
        else {
            yield put(submitProductFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitProductFormFailed(message));
    }
}

function* deleteProduct({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteProductSuccess(id));
        }
        else {
            yield put(deleteProductFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteProductFailed(message));
    }
}


export function* watchGetAllProducts() {
    yield takeEvery(GET_PRODUCTS, getAllProducts);
}
export function* watchSubmitProductForm() {
    yield takeEvery(SUBMIT_PRODUCT_FORM, submitProduct);
}
export function* watchDeleteProduct() {
    yield takeEvery(DELETE_PRODUCT, deleteProduct);
}

function* serviceSaga() {
    yield all([
        fork(watchGetAllProducts),
        fork(watchSubmitProductForm),
        fork(watchDeleteProduct),
    ]);
}

export default serviceSaga;