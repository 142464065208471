import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_REFERRAL_COMMENTS, 
    SUBMIT_REFERRAL_COMMENT_FORM,
    DELETE_REFERRAL_COMMENT,
} from '../../constants/actionTypes';


import {
    getAllReferralCommentsSuccess,
    getAllReferralCommentsFailed,

    submitReferralCommentFormSuccess,
    submitReferralCommentFormFailed,

    deleteReferralCommentSuccess,
    deleteReferralCommentFailed,

} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/referral-comments`; 

function* getAllReferralComments({ payload: { offset, limit, filter = {} } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.referralId) {
            url = `${url}&referralId=${filter.referralId}`;
        }
        const {total, data, message} = yield call(fetchJSON, url, options);
        if(message) {
            yield put(getAllReferralCommentsFailed(message));
        }
        else {
            yield put(getAllReferralCommentsSuccess({total, data}));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllReferralCommentsFailed(message));
    }
}

function* submitReferralComment({ payload: item }) {
    
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

   
    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitReferralCommentFormSuccess(response.data));
        }
        else {
            yield put(submitReferralCommentFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitReferralCommentFormFailed(message));
    }
}

function* deleteReferralComment({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteReferralCommentSuccess(id));
        }
        else {
            yield put(deleteReferralCommentFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteReferralCommentFailed(message));
    }
}



export function* watchGetAllReferralComments() {
    yield takeEvery(GET_REFERRAL_COMMENTS, getAllReferralComments);
}
export function* watchSubmitReferralCommentForm() {
    yield takeEvery(SUBMIT_REFERRAL_COMMENT_FORM, submitReferralComment);
}
export function* watchDeleteReferralComment() {
    yield takeEvery(DELETE_REFERRAL_COMMENT, deleteReferralComment);
}


function* referralCommentSaga() {
    yield all([
        fork(watchGetAllReferralComments),
        fork(watchSubmitReferralCommentForm),
        fork(watchDeleteReferralComment),
    ]);
}

export default referralCommentSaga;