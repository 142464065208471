import {
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_CATEGORIES_SUCCESS,
    GET_PRODUCT_CATEGORIES_FAILED,

    SUBMIT_PRODUCT_CATEGORY_FORM,
    SUBMIT_PRODUCT_CATEGORY_FORM_SUCCESS,
    SUBMIT_PRODUCT_CATEGORY_FORM_FAILED,

    DELETE_PRODUCT_CATEGORY,
    DELETE_PRODUCT_CATEGORY_SUCCESS,
    DELETE_PRODUCT_CATEGORY_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllProductCategories = ({offset, limit}) => ({
    type: GET_PRODUCT_CATEGORIES,
    payload: { offset, limit }
});

export const getAllProductCategoriesSuccess = ({total, data}) => ({
    type: GET_PRODUCT_CATEGORIES_SUCCESS,
    payload: {total, data}
});

export const getAllProductCategoriesFailed = (error) => ({
    type: GET_PRODUCT_CATEGORIES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitProductCategoryForm = (category) => ({
    type: SUBMIT_PRODUCT_CATEGORY_FORM,
    payload: category
});

export const submitProductCategoryFormSuccess = (category) => ({
    type: SUBMIT_PRODUCT_CATEGORY_FORM_SUCCESS,
    payload: category
});

export const submitProductCategoryFormFailed = (error) => ({
    type: SUBMIT_PRODUCT_CATEGORY_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteProductCategory = (id) => ({
    type: DELETE_PRODUCT_CATEGORY,
    payload: id
});

export const deleteProductCategorySuccess = (id) => ({
    type: DELETE_PRODUCT_CATEGORY_SUCCESS,
    payload: id
});

export const deleteProductCategoryFailed = (error) => ({
    type: DELETE_PRODUCT_CATEGORY_FAILED,
    payload: error
});