import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,

    SUBMIT_USER_FORM,
    SUBMIT_USER_FORM_SUCCESS,
    SUBMIT_USER_FORM_FAILED,

    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    users: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USERS:
            return { ...state, loading: true };
        case GET_USERS_SUCCESS:
            return { ...state, users: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_USERS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_USER_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_USER_FORM_SUCCESS:
            const alreadyExists = state.users.find((user) => user.id === action.payload.id);
            let users;
            let total = state.total;
            if (!alreadyExists) {
                users = [action.payload, ...state.users];
                total += 1;
            } else {
                users = state.users.map((user) => {
                if (user.id === action.payload.id) {
                  return action.payload;
                }
                return user;
              });
            }
            return { ...state, total,  users, submittingForm: false };
        case SUBMIT_USER_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_USER:
            return { ...state, error: null, deleting: true };
        case DELETE_USER_SUCCESS:
            return { ...state, total: state.total - 1, users: state.users.filter(branch => branch.id !== action.payload) , deleting: false };
        case DELETE_USER_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default UserReducer;