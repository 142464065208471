import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TRANSACTION_COMMENTS, 
    SUBMIT_TRANSACTION_COMMENT_FORM,
    DELETE_TRANSACTION_COMMENT,
} from '../../constants/actionTypes';


import {
    getAllTransactionCommentsSuccess,
    getAllTransactionCommentsFailed,

    submitTransactionCommentFormSuccess,
    submitTransactionCommentFormFailed,

    deleteTransactionCommentSuccess,
    deleteTransactionCommentFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/transaction-comments`; 

function* getAllTransactionComments({ payload: { offset, limit, filter = {} } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.referralId) {
            url = `${url}&referralId=${filter.referralId}`;
        }
        const {total, data, message} = yield call(fetchJSON, url, options);
        if(message) {
            yield put(getAllTransactionCommentsFailed(message));
        }
        else {
            yield put(getAllTransactionCommentsSuccess({total, data}));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTransactionCommentsFailed(message));
    }
}

function* submitTransactionComment({ payload: item }) {
    
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

   
    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTransactionCommentFormSuccess(response.data));
        }
        else {
            yield put(submitTransactionCommentFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTransactionCommentFormFailed(message));
    }
}

function* deleteTransactionComment({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteTransactionCommentSuccess(id));
        }
        else {
            yield put(deleteTransactionCommentFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTransactionCommentFailed(message));
    }
}



export function* watchGetAllTransactionComments() {
    yield takeEvery(GET_TRANSACTION_COMMENTS, getAllTransactionComments);
}
export function* watchSubmitTransactionCommentForm() {
    yield takeEvery(SUBMIT_TRANSACTION_COMMENT_FORM, submitTransactionComment);
}
export function* watchDeleteTransactionComment() {
    yield takeEvery(DELETE_TRANSACTION_COMMENT, deleteTransactionComment);
}


function* transactionCommentSaga() {
    yield all([
        fork(watchGetAllTransactionComments),
        fork(watchSubmitTransactionCommentForm),
        fork(watchDeleteTransactionComment),
    ]);
}

export default transactionCommentSaga;