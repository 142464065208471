import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_NEWS, 
    SUBMIT_NEWS_FORM,
    DELETE_NEWS
} from '../../constants/actionTypes';


import {
    getAllNewsSuccess,
    getAllNewsFailed,

    submitNewsFormSuccess,
    submitNewsFormFailed,

    deleteNewsSuccess,
    deleteNewsFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/news`;

function* getAllNews({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllNewsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllNewsFailed(message));
    }
}

function* submitNews({ payload: news }) {
    const options = {
        body: JSON.stringify(news),
        method: news.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(news.id) {
            url = `${apiUrl}/${news.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitNewsFormSuccess(response.data));
        }
        else {
            yield put(submitNewsFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitNewsFormFailed(message));
    }
}

function* deleteNews({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteNewsSuccess(id));
        }
        else {
            yield put(deleteNewsFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteNewsFailed(message));
    }
}


export function* watchGetAllNews() {
    yield takeEvery(GET_NEWS, getAllNews);
}
export function* watchSubmitNewsForm() {
    yield takeEvery(SUBMIT_NEWS_FORM, submitNews);
}
export function* watchDeleteNews() {
    yield takeEvery(DELETE_NEWS, deleteNews);
}

function* serviceSaga() {
    yield all([
        fork(watchGetAllNews),
        fork(watchSubmitNewsForm),
        fork(watchDeleteNews),
    ]);
}

export default serviceSaga;