import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILED,

    SUBMIT_PARTNER_FORM,
    SUBMIT_PARTNER_FORM_SUCCESS,
    SUBMIT_PARTNER_FORM_FAILED,

    DELETE_PARTNER,
    DELETE_PARTNER_SUCCESS,
    DELETE_PARTNER_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    partners: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const PartnerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PARTNERS:
            return { ...state, loading: true };
        case GET_PARTNERS_SUCCESS:
            return { ...state, partners: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_PARTNERS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_PARTNER_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_PARTNER_FORM_SUCCESS:
            const alreadyExists = state.partners.find((partner) => partner.id === action.payload.id);
            let partners;
            let total = state.total;
            if (!alreadyExists) {
                partners = [action.payload, ...state.partners];
                total += 1;
            } else {
                partners = state.partners.map((partner) => {
                if (partner.id === action.payload.id) {
                  return action.payload;
                }
                return partner;
              });
            }
            return { ...state, total,  partners, submittingForm: false };
        case SUBMIT_PARTNER_FORM_FAILED:
            return { ...state, error: action.payload, submittingForm: false };

        case DELETE_PARTNER:
            return { ...state, error: null, deleting: true };
        case DELETE_PARTNER_SUCCESS:
            return { ...state, total: state.total - 1, partners: state.partners.filter(partner => partner.id !== action.payload) , deleting: false };
        case DELETE_PARTNER_FAILED:
            return { ...state, error: action.payload, deleting: false };
        default: return { ...state };
    }
}

export default PartnerReducer;