import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TICKET_COMMENTS, 
    SUBMIT_TICKET_COMMENT_FORM,
    DELETE_TICKET_COMMENT,
} from '../../constants/actionTypes';


import {
    getAllTicketCommentsSuccess,
    getAllTicketCommentsFailed,

    submitTicketCommentFormSuccess,
    submitTicketCommentFormFailed,

    deleteTicketCommentSuccess,
    deleteTicketCommentFailed,

} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/ticket-comments`; 

function* getAllTicketComments({ payload: { offset, limit, filter = {} } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.ticketId) {
            url = `${url}&ticketId=${filter.ticketId}`;
        }
        const {total, data, message} = yield call(fetchJSON, url, options);
        if(message) {
            yield put(getAllTicketCommentsFailed(message));
        }
        else {
            yield put(getAllTicketCommentsSuccess({total, data}));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTicketCommentsFailed(message));
    }
}

function* submitTicketComment({ payload: item }) {
    const options = {
        body: JSON.stringify(item),
        method: item.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(item.id) {
            url = `${apiUrl}/${item.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTicketCommentFormSuccess(response.data));
        }
        else {
            yield put(submitTicketCommentFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTicketCommentFormFailed(message));
    }
}

function* deleteTicketComment({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteTicketCommentSuccess(id));
        }
        else {
            yield put(deleteTicketCommentFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTicketCommentFailed(message));
    }
}



export function* watchGetAllTicketComments() {
    yield takeEvery(GET_TICKET_COMMENTS, getAllTicketComments);
}
export function* watchSubmitTicketCommentForm() {
    yield takeEvery(SUBMIT_TICKET_COMMENT_FORM, submitTicketComment);
}
export function* watchDeleteTicketComment() {
    yield takeEvery(DELETE_TICKET_COMMENT, deleteTicketComment);
}


function* ticketCommentSaga() {
    yield all([
        fork(watchGetAllTicketComments),
        fork(watchSubmitTicketCommentForm),
        fork(watchDeleteTicketComment),
    ]);
}

export default ticketCommentSaga;