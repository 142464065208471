import {
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILED,

    SUBMIT_PRODUCT_FORM,
    SUBMIT_PRODUCT_FORM_SUCCESS,
    SUBMIT_PRODUCT_FORM_FAILED,

    DELETE_PRODUCT,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllProducts = ({offset, limit}) => ({
    type: GET_PRODUCTS,
    payload: { offset, limit }
});

export const getAllProductsSuccess = ({total, data}) => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: {total, data}
});

export const getAllProductsFailed = (error) => ({
    type: GET_PRODUCTS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitProductForm = (product) => ({
    type: SUBMIT_PRODUCT_FORM,
    payload: product
});

export const submitProductFormSuccess = (product) => ({
    type: SUBMIT_PRODUCT_FORM_SUCCESS,
    payload: product
});

export const submitProductFormFailed = (error) => ({
    type: SUBMIT_PRODUCT_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteProduct = (id) => ({
    type: DELETE_PRODUCT,
    payload: id
});

export const deleteProductSuccess = (id) => ({
    type: DELETE_PRODUCT_SUCCESS,
    payload: id
});

export const deleteProductFailed = (error) => ({
    type: DELETE_PRODUCT_FAILED,
    payload: error
});