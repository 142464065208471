import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TRANSACTIONS, 
    SUBMIT_TRANSACTION_FORM,
    DELETE_TRANSACTION,
    IMPORT_TRANSACTIONS,
    GET_TRANSACTIONS_COUNT,
    GET_CURRENT_TRANSACTION
} from '../../constants/actionTypes';


import {
    getAllTransactionsSuccess,
    getAllTransactionsFailed,

    submitTransactionFormSuccess,
    submitTransactionFormFailed,

    deleteTransactionSuccess,
    deleteTransactionFailed,

    importTransactionsSuccess,
    importTransactionsFailed,

    getTransactionsCountSuccess,
    getTransactionsCountFailed,

    getCurrentTransactionSuccess,
    getCurrentTransactionFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/transactions`;

function* getAllTransactions({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.alumniId) {
            url = `${url}&alumniId=${filter.alumniId}`;
        }
        if(filter.affiliateId) {
            url = `${url}&affiliateId=${filter.affiliateId}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllTransactionsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTransactionsFailed(message));
    }
}

function* submitTransaction({ payload: transaction }) {
    const options = {
        body: JSON.stringify(transaction),
        method: transaction.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(transaction.id) {
            url = `${apiUrl}/${transaction.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTransactionFormSuccess(response.data));
        }
        else {
            yield put(submitTransactionFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTransactionFormFailed(message));
    }
}

function* deleteTransaction({ payload: id }) {
    const options = {
        method: 'DELETE',
    };
    try {
        const url = `${apiUrl}/${id}`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(deleteTransactionSuccess(id));
        }
        else {
            yield put(deleteTransactionFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(deleteTransactionFailed(message));
    }
}

function* importTransactions({ payload: file }) {
    const options = {
        body: JSON.stringify({file}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}/import`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(importTransactionsSuccess());
        }
        else {
            yield put(importTransactionsFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(importTransactionsFailed(message));
    }
}

function* getTransactionsCount({ payload: {filter = { } } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}/count`;
        url = `${url}?isRead=${filter.isRead}`;
        const {total, message} = yield call(fetchJSON, url, options);
        if(message) {
            yield put(getTransactionsCountFailed(message));
        }
        else {
            yield put(getTransactionsCountSuccess({total}));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getTransactionsCountFailed(message));
    }
}

function* getCurrentTransaction({ payload: id }) {
    const options = {
        method: 'GET',
    };
    
    try {
        let url = `${apiUrl}/${id}`;
       
        const appraisal = yield call(fetchJSON, url, options);
        yield put(getCurrentTransactionSuccess(appraisal.data));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentTransactionFailed(message));
    }
}


export function* watchGetAllTransactions() {
    yield takeEvery(GET_TRANSACTIONS, getAllTransactions);
}
export function* watchSubmitTransactionForm() {
    yield takeEvery(SUBMIT_TRANSACTION_FORM, submitTransaction);
}
export function* watchDeleteTransaction() {
    yield takeEvery(DELETE_TRANSACTION, deleteTransaction);
}
export function* watchImportTransactions() {
    yield takeEvery(IMPORT_TRANSACTIONS, importTransactions);
}
export function* watchGetTransactionsCount() {
    yield takeEvery(GET_TRANSACTIONS_COUNT, getTransactionsCount);
}
export function* watchGetCurrentTransaction() {
    yield takeEvery(GET_CURRENT_TRANSACTION, getCurrentTransaction);
}

function* serviceSaga() {
    yield all([
        fork(watchGetAllTransactions),
        fork(watchSubmitTransactionForm),
        fork(watchDeleteTransaction),
        fork(watchImportTransactions),
        fork(watchGetTransactionsCount),
        fork(watchGetCurrentTransaction),
    ]);
}

export default serviceSaga;