import {
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILED,

    SUBMIT_CATEGORY_FORM,
    SUBMIT_CATEGORY_FORM_SUCCESS,
    SUBMIT_CATEGORY_FORM_FAILED,

    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllCategories = ({offset, limit}) => ({
    type: GET_CATEGORIES,
    payload: { offset, limit }
});

export const getAllCategoriesSuccess = ({total, data}) => ({
    type: GET_CATEGORIES_SUCCESS,
    payload: {total, data}
});

export const getAllCategoriesFailed = (error) => ({
    type: GET_CATEGORIES_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitCategoryForm = (category) => ({
    type: SUBMIT_CATEGORY_FORM,
    payload: category
});

export const submitCategoryFormSuccess = (category) => ({
    type: SUBMIT_CATEGORY_FORM_SUCCESS,
    payload: category
});

export const submitCategoryFormFailed = (error) => ({
    type: SUBMIT_CATEGORY_FORM_FAILED,
    payload: error
});

/** DELETE FORM */
export const deleteCategory = (id) => ({
    type: DELETE_CATEGORY,
    payload: id
});

export const deleteCategorySuccess = (id) => ({
    type: DELETE_CATEGORY_SUCCESS,
    payload: id
});

export const deleteCategoryFailed = (error) => ({
    type: DELETE_CATEGORY_FAILED,
    payload: error
});